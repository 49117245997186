#light-contrast-id {
  .ms-Toggle-innerContainer {
    label {
      color: black;
    }
  }
}

button.ms-Pivot-link:hover::before {
  background-color: rgb(0, 120, 212);
}

[theme=dark]:root {
  label {
    color: white;
  }

  .ms-Pivot-text {
    color: white;
  }
  .ms-Pivot-text:hover {
    color: black;
  }

  .ms-Pivot-icon {
    i {
      color: white;
    }
    i:hover {
      color: black;
    }
  }

  .ms-Pivot-linkContent:hover {
    color: black
    label {
      color: black;
    }
  }

  button.ms-Pivot-link:hover {
    color: black;
  }

  button.ms-Pivot-link:hover {
    .ms-Pivot-icon {
      i {
        color: black;
      }
    }
    .ms-Pivot-text {
      color: black;
    }
  }
}
